<template>
    <div>
        <div class="mt-3"><slot></slot></div>
        <div>
            <text-input type="text" v-model="input" :text="text"/>
            <a :href="link" v-if="input" target="_blank" class="hover:underline text-sm">{{ link }}</a>
        </div>
    </div>
</template>

<script>
import TextInput from './generator/TextInput';

export default {
    name: 'InputLink',
    props: {
        intro: {
            required: false,
        },
        text: {
            required: true,
        },
        textFormat: {
            required: true,
            type: String,
        },
    },
    components: { TextInput },
    data() {
        return {
            input: '',
        };
    },
    computed: {
        link: {
            get() {
                return this.textFormat.replace('%s', this.input);
            },
        },
    },
};
</script>

<style scoped>

</style>
